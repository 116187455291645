/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rc-video,
.rc-video-left,
.rc-video-right {
  /*.thumb::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
.h1, h1 {
  color: #98989B;
  font-weight: 100;
  font-size: 2.2rem; }

.h2, h2 {
  font-weight: normal; }

.h3, h3 {
  font-weight: normal; }

.h4, h4 {
  font-weight: normal; }

.h5, h5 {
  font-weight: normal; }

.h6, h6 {
  font-weight: normal; }

/* buttons */
.btn {
  border-radius: 0; }

.btn-primary {
  color: #454246;
  background-color: transparent;
  border: 1px solid #454246;
  transition: color .2s ease-out, border .2s ease-out, background .2s ease-out;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
    outline: none;
    color: #fff;
    background-color: #323232; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus {
    color: #fff;
    background-color: #454246;
    border: 1px solid #454246; }
  .btn-primary:active, .btn-primary.active {
    color: #fff;
    background-color: #323232; }
    .btn-primary:active:hover, .btn-primary.active:hover {
      background-color: #323232; }

/* navbar */
.navbar--main {
  border-radius: 0;
  padding: .25rem .5rem;
  background-color: #323232;
  text-transform: uppercase; }

.navbar-nav {
  text-align: center; }
  .navbar-nav .nav-item {
    float: none;
    display: inline-block; }
  .navbar-nav .nav-item + .nav-item {
    margin-left: 1.5rem; }
  .navbar-nav .nav-link {
    letter-spacing: .1rem;
    font-weight: 300;
    font-size: .9rem; }
    .navbar-nav .nav-link:hover {
      color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff; }

.navbar-dark .navbar-nav .active > .nav-link {
  color: rgba(255, 255, 255, 0.6); }

.navbar-toggler {
  color: #fff; }
  .navbar-toggler span {
    font-size: .9rem;
    line-height: 1.25rem;
    text-transform: uppercase; }

/* forms */
.form-control {
  font-size: .9rem;
  padding: .25rem .5rem;
  border-radius: 0;
  border-color: transparent;
  border-left-color: #454246;
  border-bottom-color: #454246; }
  .form-control:focus {
    border-top-color: transparent;
    border-right-color: transparent; }

/* ... */
/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "News Cycle", Helvetica, Arial, sans-serif;
  color: #454246;
  font-size: .9rem; }

/*
 *	UTILITIES
 */
.row--np {
  margin-left: 0;
  margin-right: 0; }
  .row--np [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.flex-bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end; }

/*
 *	GENERAL
 */
.info-box {
  position: relative;
  margin-bottom: 1.5rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.3rem; }
  .info-box .rc-block {
    margin-bottom: 0; }
    .info-box .rc-block p {
      margin-bottom: 0; }
  .info-box a {
    font-weight: bold;
    color: #454246; }
  .info-box::before, .info-box::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 5px;
    background-image: url("/img/border.png");
    background-repeat: repeat-x;
    background-size: auto 5px; }
  .info-box::before {
    top: 0; }
  .info-box::after {
    bottom: 0; }

/*
 *	HEADER
 */
.header {
  margin-bottom: 1.5rem; }

.logo {
  width: 290px;
  max-width: 100%;
  margin: 1.5rem auto; }

.nav-link {
  font-size: .7rem !important; }

/*
 *	NEWS
 */
.news {
  margin-bottom: 2rem; }

.news__img {
  margin-bottom: 1rem; }

.news__text .title {
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: normal;
  text-align: center;
  font-weight: 300; }

/*
 *	ETALAGE
 */
.etalage a {
  display: block; }

/*
 *	ETALAGE DETAIL
 */
.etalage-detail__info {
  font-weight: normal;
  /*
	strong {
		font-weight: 300;
	}
*/ }
  .etalage-detail__info p {
    margin-bottom: .5rem; }
    .etalage-detail__info p:last-child {
      margin-bottom: 0; }

/*
 *	CONTACT
 */
.map-wrap {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-color: rgba(69, 66, 70, 0.1); }

#map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/*
 *	FOOTER
 */
.footer {
  margin-top: 1.5rem;
  padding: 1.5rem 0;
  position: relative; }
  .footer a {
    color: #454246; }
  .footer::before {
    top: 0;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 5px;
    background-image: url("/img/border.png");
    background-repeat: repeat-x;
    background-size: auto 5px; }

.footer__contact {
  margin: 0;
  padding: 0 75px;
  text-align: center;
  letter-spacing: .1rem;
  color: gray; }
  .footer__contact li {
    display: inline-block; }
  .footer__contact li + li::before {
    content: ' - '; }

.footer__social {
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1rem;
  right: 0;
  font-size: 0; }
  .footer__social li {
    display: inline-block; }
  .footer__social a {
    font-size: 1.5rem; }

.form-control {
  padding: .13rem .5rem; }

/*
 *	IMPORTS
 */
@font-face {
  font-family: 'fontello';
  src: url("/fonts/fontello.eot?57229278");
  src: url("/fonts/fontello.eot?57229278#iefix") format("embedded-opentype"), url("/fonts/fontello.woff2?57229278") format("woff2"), url("/fonts/fontello.woff?57229278") format("woff"), url("/fonts/fontello.ttf?57229278") format("truetype"), url("/fonts/fontello.svg?57229278#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?57229278#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-cancel:before {
  content: '\e800'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-facebook-squared:before {
  content: '\f308'; }

/* '' */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 543px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (max-width: 450px) {
  .navbar-toggler {
    width: 100%; }
  .navbar-nav .nav-item {
    display: block; }
  .navbar-nav .nav-item + .nav-item {
    margin-left: 0; } }

/*# sourceMappingURL=app.css.map */
